import { ApiError } from '@tebuto/api/public'
import { ContactFormPayload } from '@tebuto/api/public/models/ContactFormPayload'
import { ERROR_MESSAGES, resolveErrorMessage, usePublicApiMutation } from '@tebuto/functions'
import { Dispatch, SetStateAction } from 'react'

export function useSendContactForm(setError: Dispatch<SetStateAction<string | null>>) {
    const { trigger } = usePublicApiMutation('sendContactForm', (api, values: ContactFormPayload) => api.contact.sendContactRequest(values), {
        onError: error => {
            let errorMessage = ERROR_MESSAGES.Unknown
            if (error instanceof ApiError) {
                errorMessage = resolveErrorMessage(error.body.type)
            }
            setError(errorMessage)
        }
    })
    return trigger
}
