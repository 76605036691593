'use client'

import {
    AdjustmentsHorizontalIcon,
    ArrowsRightLeftIcon,
    CalendarDaysIcon,
    ForwardIcon,
    HeartIcon,
    KeyIcon,
    PlayIcon,
    ShieldExclamationIcon,
    UserIcon
} from '@heroicons/react/24/outline'
import { useIsScreenWidthBelow } from '@tebuto/functions/responsiveness'
import Image from 'next/image'
import { FeaturePoint, Heading, IFeaturePoint, LandingSection } from './LandingComponents'

interface IFeature {
    title: string
    position: 'center' | 'left'
    hasShadow?: boolean
    subtitle: string
    description: React.ReactNode
    screenshotUrl: string
    mobileScreenshotUrl: string
    features: IFeaturePoint[]
    id: string
}

const features: IFeature[] = [
    {
        title: 'Alle Termine im Blick ',
        subtitle: 'Nie wieder den Überblick verlieren oder überbucht werden',
        position: 'center',
        hasShadow: true,
        id: 'termin-management',
        description: (
            <>
                <p className="text-landing">
                    Profitieren Sie von einem <b className="text-landing">übersichtlichen</b> und <b className="text-landing">effektiven Terminmanagment</b> und bieten Sie sich und
                    Ihren Klientinnen einen <b className="text-landing">nie dagewesenen Luxus</b> bei der Terminbuchung: Nutzen Sie bei der Terminplanung die Tatsache, dass Ihre
                    Termine meistens zu denselben Zeiten stattfinden, zu Ihrem Vorteil.
                </p>
                <br />
                <p className="text-landing">
                    Informieren Sie sich jederzeit in verschiedenen Sichten über die bereits <b className="text-landing">vergebenen</b> und die noch{' '}
                    <b className="text-landing">verfügbaren Termine</b>, und rufen Sie bei Bedarf Detailinformationen ab. Ermöglichen Sie Ihren Klienten{' '}
                    <b className="text-landing">maximale Flexibilität</b> bei der Terminwahl und behalten Sie dabei so viel <b className="text-landing">Kontrolle</b> wie Sie sich
                    wünschen.
                </p>
            </>
        ),
        screenshotUrl: '/assets/screenshots/alle-termine-im-blick.webp',
        mobileScreenshotUrl: '/assets/screenshots/alle-termine-im-blick-mobile.webp',
        features: [
            {
                title: 'Regelbasierte Termine ',
                description:
                    'Machen Sie die wiederkehrende Natur Ihrer Termine zu Ihrem größten Vorteil. Bieten Sie Ihren Klienten großartigen Service und Flexibilität bei minimalem Aufwand für Sie selbst.',
                Icon: AdjustmentsHorizontalIcon
            },
            {
                title: 'Individuelle Terminvergabe',
                description:
                    'Manchmal möchten Sie Ihre Termine manuell vergeben, oder Klienten möchten Tebuto nicht nutzen. Auch daran haben wir gedacht. Sie können Termine zur manuellen Vergabe reservieren oder bestimmten Klienten direkt zuweisen.',
                Icon: ArrowsRightLeftIcon
            },
            {
                title: 'Kalender integrieren',
                description:
                    'Integrieren Sie Ihren Google- und Microsoft-Kalender in Tebuto, um andere private und berufliche Ereignisse bei der Terminvergabe zu berücksichtigen - ohne diese mit uns oder Ihren Klientinnen zu teilen. Keine doppelt belegten Termine mehr.',
                Icon: CalendarDaysIcon
            }
        ]
    },
    {
        title: 'Sicher & Einfach Teilen',
        subtitle: 'Kein E-Mail Ping-Pong. Keine langwierigen Telefongespräche',
        id: 'teilen-mit-klienten',
        description: (
            <p className="text-landing">
                Statt etliche Stunden in E-Mails und Telefongesprächen zu investieren, können Sie einfach Ihre verfügbaren Termine mit Ihren Klientinnen teilen. Und zwar nur mit
                Ihren Klientinnen.
            </p>
        ),
        screenshotUrl: '/assets/terminbuchung-für-psychologen-und-therapeuten-mobile.svg',
        mobileScreenshotUrl: '/assets/terminbuchung-für-psychologen-und-therapeuten-mobile.svg',
        position: 'left',
        features: [
            {
                title: 'Passwortloser Zugang',
                description:
                    'Dank moderner, sicherer Authentifizierungstechnologien wie Einmal-Codes oder Links per E-Mail ist der Zugang für Ihre Klienten sicher und bequem - und für Sie ohne zusätzlichen Betreuungsaufwand.',
                Icon: KeyIcon
            },
            {
                title: 'Automatisieren, wenn gewünscht',
                description: 'Sie entscheiden: Entweder akzeptieren Sie die angefragten Termine Ihrer Klienten automatisch, oder Sie bestätigen die Termine einzeln.',
                Icon: ForwardIcon
            },
            {
                title: 'Verbesserte Nutzererfahrung',
                description: 'Ihre Klienten können ganz bequem und in Ruhe den Termin auswählen, der am besten passt. Auf einer einfachen und intuitiven Oberfläche.',
                Icon: HeartIcon
            }
        ]
    },
    {
        title: 'Individuelle Nachrichten und Erinnerungen',
        subtitle: 'Automatisierung mit persönlicher Note',
        id: 'kommunikation',
        description: (
            <p className="text-landing">
                Viele Ihrer <b className="text-landing">Alltagsprozesse</b> sind trotz unterschiedlicher Klienten ähnlich oder sogar identisch. Tebuto hilft Ihnen, diese Prozesse
                zu <b className="text-landing">automatisieren</b> und spart so Zeit und <b className="text-landing">verhindert Fehler</b>. Wir versenden beispielsweise
                Terminbestätigungen, Terminerinnerungen, Ausfallhonorare oder Absagen, ohne dass Sie etwas tun müssen.
            </p>
        ),
        screenshotUrl: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen.webp',
        mobileScreenshotUrl: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen-mobile.webp',
        position: 'center',
        hasShadow: true,
        features: [
            {
                title: 'Automatisch und doch persönlich',
                description:
                    'Niemand mag generische Ansprachen. Deshalb können Sie alle Benachrichtigungen an Klientinnen personalisieren und deren Gestaltung an Ihre Bedürfnisse und Ihren Stil anpassen.',
                Icon: UserIcon
            },
            {
                title: 'Anlassbasiert',
                description:
                    'Der Kontakt zwischen Ihnen und Ihren Klienten erfolgt in der Regel bei bestimmten Ereignissen oder Zeitpunkten. Diese nutzen wir als Auslöser für verschiedene automatische Benachrichtigungen, deren Inhalt Sie frei bestimmen können.',
                Icon: PlayIcon
            },
            {
                title: 'Fehlerpotential reduzieren',
                description:
                    'Tebuto unterstützt Sie dabei, Fehler in der Kommunikation zu vermeiden. Durch einfach zu bedienende Platzhalter stellen wir sicher, dass die Angaben stets korrekt sind.',
                Icon: ShieldExclamationIcon
            }
        ]
    }
]

export default function Features() {
    return (
        <LandingSection id={'features'}>
            {features.map((feature, index) => (
                <Feature key={index} feature={feature} />
            ))}
        </LandingSection>
    )
}

function Feature({ feature }: { feature: IFeature }) {
    const isMobile = useIsScreenWidthBelow(1200)

    // Centered layout if feature is centered or displayed on mobile
    if (feature.position === 'center' || isMobile)
        return (
            <div id={feature.id} className="py-44 flex flex-col gap-32">
                <div>
                    <p className="text-landing">{feature.subtitle}</p>
                    <Heading>{feature.title}</Heading>
                    <div className="mt-12">{feature.description}</div>
                </div>
                <div className="relative">
                    <Image
                        src={isMobile ? feature.mobileScreenshotUrl : feature.screenshotUrl}
                        width={1200}
                        height={800}
                        alt={feature.title}
                        className={`w-full rounded-lg z-10 relative ${feature.hasShadow && !isMobile ? 'shadow-lg' : ''}`}
                    />
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary-600 blur-[750px] h-3/4 w-3/4 z-0 max-w-full" />
                </div>
                <div className="flex flex-col gap-12 relative z-10">
                    {feature.features.map((featurePoint, index) => (
                        <FeaturePoint key={index} feature={featurePoint} />
                    ))}
                </div>
            </div>
        )

    if (feature.position === 'left') {
        return (
            <div id={feature.id} className="py-44 gap-32 grid grid-cols-3">
                <div className="flex items-center relative">
                    <Image
                        src={isMobile ? feature.mobileScreenshotUrl : feature.screenshotUrl}
                        width={1200}
                        height={800}
                        alt={feature.title}
                        className={`w-full rounded-lg z-10 relative ${feature.hasShadow ? 'shadow-lg' : ''}`}
                    />
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary-600 blur-[750px] h-full w-48 max-w-full z-0" />
                </div>
                <div className="col-span-2">
                    <p className="text-landing">{feature.subtitle}</p>
                    <Heading>{feature.title}</Heading>
                    <div className="mt-12">{feature.description}</div>
                    <div className="flex flex-col gap-12 mt-20">
                        {feature.features.map((featurePoint, index) => (
                            <FeaturePoint key={index} feature={featurePoint} />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
